import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"
import { useDispatch, useSelector } from "react-redux"
import SEO from "./../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import { PrevisuCoinCadre } from "@bko/previsus"
import Croquis from "../components/croquis"
import Categories from "../components/categories"
import slugify from "slugify"
import { setDimensions, mjsService, clearDimensions } from "../actions/cadre"
import { setPp } from "../actions/passe"
import { getFilterMaxis, groupByColor, validDimension } from "../services/cadre"
import { getSizeInfos } from "../profcadresprix/index-legacy"
import { DisplayTirage } from "../components/displayTirage"
import { cancelPhoto } from "../actions/photos"
import ChoixDimensions from "../components/choix/choixDimensions"
import Slide from "react-reveal/Slide"
import ChoixCouleur from "../components/choix/choixCouleur"
import { getPrix } from "../services/panier"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Pulse from "react-reveal/Pulse"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle, faHandPointRight } from "@fortawesome/free-solid-svg-icons"
import { categsNoPasse } from "@bko/optionsproduits"

const CategDescription = (props) => {
  switch (props.catId) {
    case 1: // alu
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le cadre aluminium
          </div>
          <p>
            L’aluminium permet de proposer des cadres de faible section en <strong>grand format</strong>.
          </p>
          <p>
            Vous pouvez choisir un <strong>cadre aluminium</strong> pour un effet minimaliste, très épuré, une texture métal.
          </p>
          <p>Le cadre alu est plus léger qu’un cadre bois.</p>
        </div>
      )
    case 2: // bois
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le cadre bois
          </div>
          <p>
            L’incontournable pour tous types de sujets, avec ou sans <strong>passe partout</strong> ou <strong>verre</strong> de protection :{" "}
            <strong>cadre peinture</strong>, <strong>cadre dibond</strong>, cadre aquarelle, <Link to="/tirage-photo-encadre/">cadre photo</Link>, cadre poster,
            cadre affiche, cadre PLV, cadre diplome, cadre puzzle, cadre pour carte en relief, etc.
          </p>
          <p>
            La baguette bois permet tous les effets décoratifs et moulures d’encadrement. On retrouve par exemple le cadre baroque, le cadre style régence,
            cadre ornement, <Link to="/cadres/Cadre-bois-Argent-Plomb-Champagne-Vermeille/">cadre texture argent</Link> ou{" "}
            <Link to="/cadres/Cadre-bois-Or-Cuivre/">cadre or oxydé</Link>, <Link to="/cadres/Cadre-bois-Blanc-Blanchi/">cadre bois vieilli</Link>, etc.
          </p>
          <p>Le profil de baguette cadre bois peut être très large, plus de 8cm, contrairement au profil alu.</p>
          <p>
            Le <strong>cadre sous verre</strong> (cadre complet incluant un verre et un isorel fond de cadre) est idéal pour tout support papier (photos, etc.).
          </p>
        </div>
      )
    case 3: // caisse américaine peinture
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> La caisse américaine pour toile
          </div>
          <p>
            Le <strong>cadre américain pour peinture</strong> est un cadre très contemporain, très utilisé dans les galeries d’art.
          </p>
          <p>
            Très minimaliste, la <strong>caisse américaine flottante</strong> s’intègre dans tous les styles d’intérieur : moderne, contemporain, etc.
          </p>
        </div>
      )
    case 4: // caisse américaine dibond
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> La caisse américaine pour dibond
          </div>
          <p>
            La caisse américaine pour support rigide est adaptée pour votre <strong>dibond</strong> ou <strong>pharex</strong>.
          </p>
          <p>
            Le sujet se colle sur le nez de feuillure du cadre donnant un effet flottant, d’où le nom de <strong>caisse américaine flottante</strong>.
          </p>
          <p>
            Très épuré, le <strong>cadre américain</strong> s’intègre dans tous les styles d’intérieur : moderne, contemporain, etc.
          </p>
        </div>
      )
    case 5: // caisse américaine support papier
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> La caisse américaine pour support papier
          </div>
          <p>
            La <strong>caisse américaine</strong> a un design très minimaliste. Elle s’intègre dans tous les styles d’intérieur : moderne, contemporain, etc.
          </p>
          <p>
            Le sujet se met à l’intérieur du cadre, ce qui permet de mettre un <strong>verre</strong> de protection pour les supports papier :{" "}
            <Link to="/tirage-photo-encadre/">photo encadrée</Link>, cadre aquarelle, cadre lithographie, cadre poster, cadre affiche, cadre PLV, cadre diplôme,
            etc.
          </p>
          <p>
            Un <strong>passe partout</strong> est recommandé pour mettre en valeur votre sujet et éviter que la photo touche le verre.
          </p>
        </div>
      )
    case 6: // marie louise
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le cadre marie louise
          </div>
          <p>L’encadrement marie louise donne de la profondeur et évite que le sujet exprime le sentiment d’être enfermé.</p>
          <p>Elle est souvent de couleur claire (crème ou neutre)</p>
          <p>
            Le <strong>cadre marie louise</strong> est utilisé pour encadrer des <strong>peintures</strong> ou des <strong>cartons toilés</strong>.
          </p>
        </div>
      )
    case 7: // vitrine
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le cadre réhausse (loft)
          </div>
          <p>Ce montage spécifique permet un espace entre le verre et le sujet, pour que le sujet ne touche pas le verre (réhausse entre 15 et 30mm).</p>
          <p>
            Le cadre vitrine est fortement apprécié pour une <strong>exposition de photos</strong>
          </p>
        </div>
      )
    case 8: // entre 2 verres
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le cadre entre deux verres
          </div>
          <p>
            Le cadre entre deux verres est une conception unique proposée par notre groupe <a href="https://www.producadre.fr/">les professionnels du cadre</a>.
          </p>
          <p>Le sujet est glissé entre deux plaques de verre de plus grandes dimensions, maintenues par le cadre.</p>
          <p>La transparence de l’espace entre le sujet et le cadre donnera l’impression que le sujet flotte.</p>
          <p>
            Un produit original pour votre <Link to="/tirage-photo-encadre/">cadre photo sur mesure</Link>, cadre poster, cadre affiche, cadre lithographie,
            etc.
          </p>
        </div>
      )
    case 9: // astral
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le cadre astral
          </div>
          <p>
            Ce montage est une exclusivité du groupe <a href="https://www.producadre.fr/">les professionnels du cadre</a>.
          </p>
          <p>
            Le passe partout est remplacé par un vide. Cela permet la mise en valeur d’un <strong>encadrement dibond</strong> ou{" "}
            <strong>sublimation chromalux</strong> par un design élégant et moderne.
          </p>
        </div>
      )
    case 11: // doublo design
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le cadre doublo design
          </div>
          <p>« Doublo » signifie deux cadres imbriqués l’un dans l’autre.</p>
          <p>Vous configurez votre cadre pour l’adapter parfaitement par rapport à l’œuvre encadrée.</p>
          <p>
            Le double cadre peut être mis avec un <strong>passe partout</strong> pour personnaliser le cadre par rapport au sujet.
          </p>
          <p>
            La baguette intérieure permet de donner de l’éclat au sujet, donner du caractère au cadre, reprendre une couleur du sujet pour la mettre en valeur.
          </p>
        </div>
      )
    case 12: // doublo caisse
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le double cadre pour peinture
          </div>
          <p>Ce montage est utilisé par les encadreurs chevronnés qui maitrisent la fabrication complète du cadre.</p>
          <p>Le double cadre caisse américaine permet de proposer des cadres exceptionnels qui sortent de l’ordinaire.</p>
          <p>
            La <strong>caisse américaine flottante</strong> est un encadrement très contemporain, largement utilisée dans les galeries d’art.
          </p>
        </div>
      )
    case 13: // doublo ML
      return (
        <div className="box mt-5 has-text-justified">
          <div className="h2 is-size-5 mt-0">
            <FontAwesomeIcon icon={faInfoCircle} /> Le double cadre marie louise
          </div>
          <p>Le double cadre marie louise permet de personnaliser et adapter le cadre par rapport au sujet.</p>
          <p>
            Le rendu est magnifique et <strong>haut de gamme</strong>, par exemple un cadre avec baguette extérieure en noir et baguette intérieure en blanc. On
            utilise souvent des couleurs tranchées et opposées pour accenturer le constraste.
          </p>
          <p>
            Le double cadre marie louise a la même fonction qu’un <strong>passe partout</strong> mais il est encore plus esthétique.
          </p>
        </div>
      )
    default:
      return null
  }
}

CategDescription.propTypes = {
  catId: PropTypes.number,
}

const FamilysPage = (props) => {
  const dispatch = useDispatch()
  const pageProduit = useSelector((s) => s.PageProduit)
  const photosR = useSelector((s) => s.photosR)
  const [familiesRuptures, setFamiliesRupture] = useState([])
  const [currentColor, setCurrentColor] = useState(null)
  const [currentCaisseMontage, setCurrentCaisseMontage] = useState(null)

  const scrollToProducts = useRef(null)

  useEffect(() => {
    dispatch(setPp(props.pageContext.defaultPasse))
    dispatch(mjsService("coupe_assemble_option"))
    dispatch(clearDimensions())
    if (props.pageContext.dimension !== null) {
      dispatch(setDimensions(parseInt(props.pageContext.dimension[0]), parseInt(props.pageContext.dimension[1])))
    }
    setFamiliesRupture(getFamiliesRupture(props.pageContext.products))
    if (props.pageContext.couleur !== null && typeof window !== "undefined") {
      console.log("scroll")
      window.scrollTo(0, 600)
    }
  }, [])

  useEffect(() => {
    if (pc.categorie?.cat_id === 5) {
      if (photosR.tirage) {
        if (photosR.tirage === "tirage_sublimation" && (!currentCaisseMontage || currentCaisseMontage.indexOf(3) < 0)) {
          setCurrentCaisseMontage([3])
        } else {
          if (!currentCaisseMontage || currentCaisseMontage.indexOf(12) < 0) setCurrentCaisseMontage([12])
        }
      } else {
        if (pageProduit.pp.type !== "aucun" && pageProduit.pp.type !== null && (!currentCaisseMontage || currentCaisseMontage.indexOf(12) < 0)) {
          setCurrentCaisseMontage([12])
        }
      }
    }
  }, [photosR?.tirage, pageProduit?.pp?.type])

  const filterRule =
    props.pageContext.dimension !== null
      ? (e) => (currentColor === null || currentColor === e.cog_id || e.otherColors.indexOf(currentColor) > -1) && (currentCaisseMontage === null || currentCaisseMontage.indexOf(e.cat_id_originale) >= 0)
        && ([null, "aucun"].includes(pageProduit.pp.type) || categsNoPasse.indexOf(e.cat_id_originale) < 0)
      : (e) => {
          // IMPORTANT : A chaque modification de cette page, RECHARGER, le hmr ne fonctionnera pas sur le constructeur
          let dimensionValided = validDimension(e, getSizeInfos(pageProduit, e, null).largeurIntra, getSizeInfos(pageProduit, e, null).hauteurIntra)
          if (Object.keys(photosR.photos).length >= 1) {
            // Si il y a des photos de chargées
            return (
              (currentColor === null || currentColor === e.cog_id || e.otherColors.indexOf(currentColor) > -1) && 
              (currentCaisseMontage === null || currentCaisseMontage.indexOf(e.cat_id_originale) >= 0) &&
              dimensionValided &&
              Object.keys(photosR.photos).length >= 1 &&
              (e.dispo_tirage_photo === 1 || e.dispo_tirage_sublimation === 1) &&
              ((["tirage", "tirage_haut_de_gamme"].includes(photosR.tirage) && e.cat_id_originale !== 3)|| (photosR.tirage === "tirage_sublimation" && e.dispo_tirage_sublimation)) &&
              ([null, "aucun"].includes(pageProduit.pp.type) || categsNoPasse.indexOf(e.cat_id_originale) < 0)
            )
          } else {
            // Sinon :
            return (currentColor === null || currentColor === e.cog_id || e.otherColors.indexOf(currentColor) > -1) && 
              (currentCaisseMontage === null || currentCaisseMontage.indexOf(e.cat_id_originale) >= 0) &&
              dimensionValided &&
              ([null, "aucun"].includes(pageProduit.pp.type) || categsNoPasse.indexOf(e.cat_id_originale) < 0)/*||
              ([null, "aucun"].includes(pageProduit.pp.type) &&
                getSizeInfos(pageProduit).perimetreIntra <= 600 &&
                getSizeInfos(pageProduit).largeurIntra <= 150 &&
                getSizeInfos(pageProduit).hauteurIntra <= 150)*/
          }
        }
  const getFamiliesRupture = (products) => {
    let familiesAvailable = []
    products.forEach((node) => {
      if (typeof familiesAvailable[node.fa_id + "-" + node.fa_libelle] == "undefined") familiesAvailable[node.fa_id + "-" + node.fa_libelle] = true
      if (node.ba_continue !== 2) {
        // rupture
        familiesAvailable[node.fa_id + "-" + node.fa_libelle] = false
      }
    })
    return familiesAvailable
  }

  const filterSizes = (e) => {
    return (
      (getSizeInfos(pageProduit).perimetreIntra <= getFilterMaxis(e, photosR.tirage).ba_perimetre_maxi &&
        getSizeInfos(pageProduit).largeurIntra <= getFilterMaxis(e, photosR.tirage).ba_maxi &&
        getSizeInfos(pageProduit).hauteurIntra <= getFilterMaxis(e, photosR.tirage).ba_maxi) ||
      ([null, "aucun"].includes(pageProduit.pp.type) &&
        getSizeInfos(pageProduit).perimetreIntra <= 600 &&
        getSizeInfos(pageProduit).largeurIntra <= 150 &&
        getSizeInfos(pageProduit).hauteurIntra <= 150)
    )
  }

  const renderTiragePhoto = () => {
    return (
      <div className="familyHeader">
        <div className="familyHeaderTitle">Le tirage photo que j&apos;ai sélectionné :</div>
        <div className="familyHeaderContent">
          <DisplayTirage cols={pageProduit.pp.ouvertures[0]} rows={pageProduit.pp.ouvertures[1]} />
          <Link to={`/tirage-photo-encadre/`}>
            <button className="button is-small" data-cy="modifier-tirage-famille">
              Modifier
            </button>
          </Link>
          &nbsp;
          <Link onClick={() => dispatch(cancelPhoto())} to={`/cadres/`}>
            <button className="button is-small" data-cy="annuler-tirage-famille">
              Annuler service image encadrée
            </button>
          </Link>
        </div>
      </div>
    )
  }

  const renderProducts = (pc) => {
    if (pc.products.filter(filterRule).length === 0) return

    const labelProduct = `${pc.categorie !== null ? `${pc.categorie.cat_libelle}` : "Cadre"} ${
      pc.couleur !== null ? pc.couleur.libelle.toLocaleLowerCase() : ""}${
      pc.dimension !== null ? (pc.dimension[2] ? pc.dimension[2] : `${pc.dimension[0]} x ${pc.dimension[1]} cm`)  : ""}`;

    return (
      <>
        <div className="columns is-multiline is-centered listeCadre">
          {groupByColor(pc.products.slice().reverse(), currentColor)
            .sort((a, b) => a.weight - b.weight)
            .filter(filterRule)
            .map((familly) => (
              <div key={familly.ba_id + '-' + (familly.doublo_defaut ? familly.doublo_defaut.ba_id : '')} className="column is-narrow">
                <Link
                  to={`/cadre/${slugify(familly.cat_libelle)}-${slugify(familly.fa_libelle)}-${slugify(familly.co_libelle)}-${familly.ba_id}/`}
                  className="box"
                >
                  <div style={{position: "relative", top: -12, fontWeight: "bold"}}>{labelProduct}</div>
                  <div className="columns is-mobile">
                    <div className="column is-narrow">
                      <div style={{ width: "74px", textAlign: "right" }}>
                        <div className="h4">{familly.fa_libelle}</div>
                        <div className="nuances">{familly.nmbre_color === 1 ? "" : familly.nmbre_color > 1 ? familly.nmbre_color + " couleurs" : ""}</div>
                        <Croquis
                          url={familly.croquis_svg !== null ? familly.croquis_svg : familly.croquis}
                          feuillure={!familly.doublo_defaut ? familly.ba_hauteur_feuillure : familly.doublo_defaut.ba_hauteur_feuillure}
                          product={familly}
                          largeurFixe={70}
                          hauteurMax={50}
                          tailleCote={0}
                          rehausse={null}
                          hideText={true}
                          doublo={familly.doublo_defaut ? familly.doublo_defaut : null}
                          showHauteurFeuillure={typeof pc.category !== "undefined" && ![18, 3].includes(pc.category.cat_id)}
                        />
                        <br />
                        <b>
                          <p className={"DetailCroquis"}>
                            Largeur :{" "}
                            {parseFloat((familly.doublo_defaut ? familly.ba_talon + familly.doublo_defaut.ba_largeur : familly.ba_largeur).toFixed(2))}
                            cm
                          </p>
                        </b>
                        <p className={"DetailCroquis"}>
                          Epaisseur :{" "}
                          {parseFloat(
                            (familly.doublo_defaut
                              ? familly.ba_hauteur - familly.ba_hauteur_feuillure + familly.doublo_defaut.ba_hauteur
                              : familly.ba_hauteur
                            ).toFixed(2)
                          )}
                          cm
                        </p>
                      </div>
                    </div>
                    <div className="column is-narrow is-relative">
                      {familly.ba_fk_id_style === 47 && familly.cat_id !== 17 && familly.fa_id !== 504 ? <div className="plaquage">Placage véritable bois</div> : ""}
                      <Slide right>
                        <div className="labels">
                          {familiesRuptures[familly.fa_id + "-" + familly.fa_libelle] && currentColor !== null ? (
                            <div>
                              <span className="rupture">EN RUPTURE</span>
                            </div>
                          ) : null}
                        </div>
                      </Slide>
                      <div
                        style={{
                          background: familly.cog_id === 3 || (familly.doublo_defaut && familly.doublo_defaut.cog_id === 3) ? "#9f8f7e" : "none",
                        }}
                      >
                        <PrevisuCoinCadre
                          imageComponent={GatsbyImage}
                          image={getImage(familly.imgCoin)}
                          ba_largeur={familly.ba_largeur > 39 ? 10 : familly.ba_largeur}
                          ba_talon={familly.ba_talon}
                          baguette={familly}
                          baguetteInterieure={
                            !familly.doublo_defaut
                              ? null
                              : {
                                  ...familly.doublo_defaut,
                                  imgLongueur: getImage(familly.doublo_defaut.imgLongueur),
                                  imgCoin: getImage(familly.doublo_defaut.imgCoin),
                                }
                          }
                        />
                      </div>
                      <div style={{ position: "absolute", top: 147, right: 10 }}>
                        {pc.dimension !== null || !pageProduit.dimensionDefault ? (
                          <div className="price">
                            {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                              getPrix(familly, { ...pageProduit, doublo: familly.doublo_defaut }, pc.pa, photosR)
                            )}
                            *
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </>
    )
  }

  const pc = props.pageContext
  const title = `${pc.categorie !== null ? `${pc.categorie.cat_libelle}` : "Cadre"} ${
    pc.couleur !== null ? pc.couleur.libelle.toLocaleLowerCase() : ""}${
    pc.dimension !== null ? (pc.dimension[2] ? pc.dimension[2] : `${pc.dimension[0]}x${pc.dimension[1]}`)  : ""}`
  let seoText = null

  if (pc.couleur === null && pc.dimension === null) {
    switch (pc.categorie.cat_libelle) {
      case "Cadre bois":
        seoText = (<div className="container mt-6">
          <p>Le <strong>cadre bois</strong> est un élément central de la décoration. Bien choisi, il permet de révéler
          tout le potentiel d’une œuvre d’art, peinture ou photographie. Le cadre en bois, aussi appelé
          « cadre baguette », présente un esthétisme chaleureux et élégant d’une grande qualité ; il
          vous offrira un très bel élément décoratif au sein de votre intérieur.</p>
          <p>La boutique Encadrement sur mesure, pionnière dans le secteur de la vente de cadres sur
          Internet, vous propose une belle gamme de cadres en bois aux styles colorés, moulurés ou
          simples, variés à faire réaliser sur mesure.</p>
          <h2>Le cadre en bois : un véritable objet d’art</h2>
          <p>Le cadre bois est un élément décoratif, ancien, dont l’œuvre d’art ne saurait se défaire,
          et cela, depuis le Moyen Âge, car il fait partie intégrante de cette dernière. Son choix n’est
          jamais anodin, il ne peut l’être ! Il guide l’œil attentif de celui qui l&#39;observe afin de lui indiquer
          pourquoi l&#39;auteur de l&#39;œuvre a choisi un tel cadrage.</p>
          <p>Un cadre en bois, par ailleurs, s’intégrera à tous les intérieurs aussi bien de style ancien,
          classique ou contemporain. Le mélange contemporain/ancien demeure d’ailleurs
          particulièrement à la mode.</p>
          <p>Il permet également de protéger l’œuvre présentée afin que cette dernière ne subisse pas le
          moindre choc lorsqu’elle est déplacée. Robuste, résistant, il vous offrira une durabilité
          exceptionnelle et passera de génération en génération.</p>
          <h2>Pourquoi faire réaliser un cadre en bois sur mesure ?</h2>
          <p>Commander un <strong>cadre en bois</strong> sur mesure est simple. Il vous suffit, une fois sur notre site,
          de renseigner les dimensions souhaitées pour votre cadre puis de choisir la couleur que
          vous aimeriez pour votre cadre. Optez ensuite pour un modèle de baguette selon qu’elle doit
          être épaisse ou fine, moulurée ou lisse. Les dimensions de votre cadre bois doivent
          correspondre à l’ouvrage que vous souhaitez encadrer.</p>
          <p>En fonction des dimensions de votre cadre, de nombreux choix vous seront
          accessibles : avec ou sans fond isorel, avec ou sans verre, préassemblé ou en kit…à travers
          les étapes de votre commande, laissez-vous guider, conseils à l’appui.</p>
          <p>En commandant votre cadre bois, vous aurez à disposition un grand choix de verre qui
          répondront à vos exigences. Plexi, verre normal, verre anti-reflet et même le verre qualité
          musée ; fortement apprécié par les photographes, galeristes d’art, ou par ceux qui veulent
          protéger leurs œuvres.</p>
          <p>En effet, le verre qualité musée permet de :</p>
          <ul><li>- Protéger contre les UV à hauteur de 70%</li>
          <li>- Préserver les couleurs de l’œuvre et diminuer les reflets</li></ul>
          <p>N’hésitez pas avant de passer commande à nous demander un échantillon que nous vous
          ferons parvenir dans les plus brefs délais à un prix modique avec des frais de port et
          emballage offert. Et si vous avez besoin de précisions, contactez-nous en remplissant notre
          formulaire en ligne. Vous recevrez votre commande dans un délai de huit jours ouvrés ou un
          jour ouvré si vous sélectionnez l’option « EXPRESS ». Sachez aussi que vous bénéficiez de
          10% de réduction dès 250 euros d’achat et 20% pour toute commande supérieure à 450€.</p>
          <p>Et n’oubliez pas, enfin, de choisir un passe-partout que nous réalisons aussi sur mesure,
          dans un coloris adapté grâce auquel l&#39;œil respire en créant un espace entre le cadre et la
          peinture, par exemple, et sublimer ainsi davantage l’œuvre encadrée.</p>
          <p>Il ne vous reste plus qu’à installer votre cadre bois à hauteur des yeux ; contemplez
          alors la beauté de l&#39;œuvre.</p>
        </div>)
        break;
      case "Cadre vitrine":
        seoText = (<div className="container mt-6">
          <p>Sublimez vos œuvres les plus magistrales grâce à un <strong>cadre vitrine</strong> d&#39;excellente facture.
          Offrez ainsi de la profondeur à vos visuels et protégez-les du temps grâce à la pose d&#39;un
          verre protecteur et de baguettes de rehausse qui permettront d’avoir un espace de 1.5cm à
          3cm entre le verre et le sujet.</p>
          <p>La boutique, Encadrement sur mesure, pionnière dans la conception et la fabrication de
          cadres sur mesure en ligne, vous propose une belle gamme de cadres vitrine disponibles
          dans des teintes variées -or, argent, blanc, couleur bois, marron, noir, gris, cuivre- plaquage
          bois véritable…qui mettront en valeur le sujet encadré. Nos baguettes de rehausse offriront
          une excellente protection à votre travail et un effet visuel élégant.</p>
          <p>Vous pourrez commander des rehausses de différentes couleurs : noir, blanc, or, argent,
          naturelle prête à peindre qui vous permettra, si vous le désirez , de personnaliser votre
          cadre en la teintant selon vos envies.</p>
          <h2>Le cadre vitrine : la solution idéale pour mettre en valeur vos photos</h2>
          <p>Le cadre vitrine, autrement appelé cadre boîte, est un dispositif mural qui permet d&#39;encadrer
          différents types de supports : photographies, peintures, aquarelles, pastels. Son originalité,
          cependant, réside dans le fait de pouvoir présenter aussi des œuvres aux formes diverses
          comme des objets.</p>
          <p>Le <strong>cadre vitrine</strong> demeure la solution idéale pour mettre en valeur les œuvres, car il confère
          une profondeur visuelle très agréable pour l’œil et sublime ainsi votre sujet. Ce type
          d’encadrement est, par conséquent, le meilleur allié de l’artiste professionnel ou amateur,
          qui a pour objet de transmettre quelque chose : un message, un sentiment, une émotion.</p>
          <p>Afin de magnifier, par ailleurs, votre travail, faites le choix du sur mesure tel que notre
          entreprise vous le propose. Pour ce faire, rien de plus simple, il suffit de renseigner les
          dimensions de votre œuvre et de sélectionner la couleur du cadre que vous souhaitez.</p>
          <p>Il est à noter que, pour un <strong>cadre vitrine</strong> seuls le verre normal ou le verre qualité musée
          vous seront proposés. Les autres types de verres ne le sont pas pour des raisons
          esthétiques.</p>
          <p>Pour la réalisation de projets d’envergure, vous bénéficierez de 10% de réduction dès 250
          euros d’achat et de 20% de réduction dès 450 euros d’achat. Vous serez livré dans un délai
          de huit jours ouvrés ou d’un jour ouvré si vous choisissez l’option EXPRESS.</p>
          <h2>Le cadre vitrine doté d’une rehausse : pour une pérennité de l’œuvre</h2>
          <p>Le <strong>cadre vitrine</strong>, s’avère être une solution idéale pour encadrer des sujets d’une épaisseur
          jusqu’à 3cm.</p>
          <p>Le montage spécifique du cadre vitrine, grâce à ses baguettes de rehausse d&#39;une épaisseur
          comprise entre 15 mm et 30 mm, offre un espace suffisant entre le sujet et le verre de
          l’encadrement de sorte que l’œuvre respire, semble même pouvoir se mouvoir librement.</p>
          <p>L&#39;ajout d&#39;un passe-partout sur mesure permettra, en outre, à l’œil de lire plus facilement
          l’œuvre en créant une respiration entre le sujet encadré et le cadre. Le visuel s’en trouvera,
          par conséquent, allégé. Toutes nos rehausses ne sont pas fixées au cadre afin que vous
          puissiez les retirer sans aucun soucis.</p>
          <p>Grâce à ce système, exposez sans la moindre crainte votre travail ; vous aurez l’assurance
          que celui-ci sera mis en valeur.</p>
        </div>)
        break;
      case "Caisse américaine":
        seoText = (<div className="container mt-6">
          <p>La <strong>caisse américaine</strong> est un encadrement au design épuré et minimaliste qui s’intègre
          dans tous les intérieurs. Il est parfait pour réaliser des expositions artistiques, car le sujet est
          mis en valeur grâce aux lignes simples de la caisse américaine qui ne noient pas l&#39;effet
          visuel que l&#39;artiste a voulu exprimer.</p>
          <p>La boutique en ligne d’Encadrement sur mesure vous invite à découvrir sa large gamme de
          caisses américaines disponibles dans de nombreux formats standards ou sur-mesure, de
          couleur noir, blanc et couleurs diverses, naturels ou prêt à peindre, vous pourrez également
          découvrir les doublos en exclusivité qui vous permettront de personnaliser vos caisses
          américaines. De ce fait, le travail de l’artiste sera magnifié.</p>
          <p>Que vous choisissiez un support pour châssis peinture, support rigide, ou encore support
          papier, optez pour le système de caisse américaine qui convient.</p>
          <h2>La caisse américaine : la solution idéale pour sublimer vos œuvres sur papier, sur toile ou sur dibon</h2>
          <p>Grâce à la caisse américaine, sublimez vos œuvres. La sobriété en effet de cet
          encadrement offre une large place au travail de l’artiste. L’œil de l’observateur se fixe
          directement sur le sujet et le propos exprimé et ce dernier est par conséquent parfaitement
          mis en relief. C’est pourquoi la caisse américaine reste un support de présentation idéal
          pour les expositions dans les salons ou les galeries.</p>
          <p>De la même façon, la caisse américaine par son design contemporain s’intégrera
          parfaitement à tous les types d’intérieurs qu’il soit de style traditionnel ou actuel et vous
          offrira une décoration du meilleur goût.</p>
          <h2>Quel type de caisse américaine choisir ?</h2>
          <p>Il existe trois types de systèmes de caisse américaine : pour châssis peinture, support rigide
          ou bien support papier.</p>
          <p>La caisse américaine pour peinture offre une profondeur permettant d’y positionner des toiles sur châssis
          afin de les encadrer d’une façon épurée.</p>
          <p>Le choix de la caisse américaine support rigide reste quant à lui idéal et spécialement conçu
          pour mettre en valeur les dibons, plexi, farex etc…qui se collera sur le nez de la feuillure qui permettra d’avoir un effet flottant.</p>
          <p>Le modèle de caisse américaine pour support papier reste par ailleurs le plus adapté pour
          encadrer vos photographies, aquarelles, posters, lithographies, affiches et diplômes, car
          votre sujet se glissera dans le cadre et sera protégé par un verre et sera tenu par un fond
          isorel qui évitera qu’il se déforme. Avec cette version, il vous sera possible de mettre un
          passe partout qui valorisera davantage votre œuvre.</p>
          <p>Une fois votre choix fait, commandez votre <strong>caisse américaine</strong>. Pour ce faire, suivez les
          étapes suivantes. Cliquez sur la caisse américaine que vous désirez puis inscrivez les
          dimensions de votre sujet de façon à réaliser un encadrement sur mesure de qualité.
          Sélectionnez ensuite les variations de couleurs du cadre et importez, si vous le souhaitez,
          votre sujet sur notre site qui vous permettra d’avoir un rendu de l’ensemble et confortera
          votre choix. Pour finir, ajoutez votre commande à votre panier. Sinon pour être rassuré dans
          votre choix, il est possible commander un échantillon du cadre sélectionné.</p>
          <p>Vous recevrez enfin votre commande dans un délai d’environ huit jours ouvrés (si vous
          optez pour le traitement express : votre commande sera expédiée le lendemain de nos
          ateliers) et une fois votre caisse américaine en main, il ne vous restera plus qu’à mettre votre
          sujet en place.</p>
        </div>)
        break;
      case "Cadre aluminium":
        seoText = (<div className="container mt-6">
          <p>Sublimez vos photographies grâce à un encadrement élégant et raffiné. Le <strong>cadre
          aluminium</strong> constitue une solution idéale pour valoriser votre œuvre et la protéger
          durablement. Léger et facile à installer, ce type d’encadrement vous offrira un rendu de
          qualité.</p>
          <p>L’entreprise Encadrement sur mesure vous invite à découvrir sa sélection de cadres en
          aluminium disponibles dans plusieurs coloris, selon le modèle choisi, -noir, blanc, or, cuivre,
          argent, bordeaux, rouge, bleu, violet, marron, jaune, gris, orange, vert ou rose dans un
          format adapté à votre œuvre de sorte que cette dernière ne soit nullement dénaturée, mais
          au contraire mise en valeur et magnifiée.</p>
          <h2>Le cadre en aluminium : un encadrement chic et contemporain</h2>
          <p>Le cadre en aluminium demeure un classique de l’encadrement contemporain. Son design
          s’intégrera dans tous les intérieurs. Au sein d’une habitation moderne, il aura toute sa place
          grâce à son style épuré et minimaliste et dans une demeure au goût traditionnel, il créera un
          subtil mélange entre ancien et nouveau, association très en vogue.</p>
          <p>Sa légèreté et sa finesse en font par ailleurs un encadrement de choix. L’épure de ses
          formes permet de mettre en lumière le sujet et seulement celui-ci ! L’œil ne se perd pas et
          fixe directement le sujet que l’artiste a voulu mettre en avant. Le cadre devient presque un
          simple support, bien plus qu’un objet d’art.</p>
          <p>L’encadrement aluminium permet d’avoir une section de cadre beaucoup moins imposante
          par rapport au cadre bois et ceci est fortement apprécié par les personnes qui recherchent
          un encadrement très discret et minimaliste, surtout dans les grandes dimensions.</p>
          <h2>Le cadre en aluminium : une solution idéale pour protéger vos photographies</h2>
          <p>Un <strong>cadre aluminium</strong> présente également une belle durabilité qui vous permettra de
          protéger vos œuvres durant des années. Il résiste particulièrement bien aux chocs si, par
          mégarde, vous le faites tomber.</p>
          <p>La mise en place de votre photographie, aquarelle, poster, lithographie, etc… en outre reste
          simple et rapide. Pour ce faire, retirez les ressorts qui permettent de positionner votre sujet
          sur l’avant du cadre puis, à l’aide d’un tournevis plat, dévissez un côté du cadre en
          aluminium alors tenu par une équerre à vis et glissez-y votre travail d’artiste. Une fois en
          place, revissez. Il ne vous reste plus qu’à accrocher au mur votre cadre en aluminium et
          exposer aux yeux de tous votre œuvre.</p>
          <p>Quelques cadres en aluminium tels que le ALU 10 sont assemblés sertis. La mise en place
          du sujet dans le cadre est alors encore plus simple : il suffit de retirer  les ressorts sur
          l’arrière du cadre, enlever le fond isorel et glisser le sujet dans le cadre sans dévisser les
          angles.</p>
          <p>Pour toutes vos questions n’hésitez pas à nous contacter en nous envoyant un formulaire
          depuis notre site Internet. Sauf si vous optez pour le service précoupé : vous recevrez le
          cadre à vos dimensions coupé en onglet avec son sachet d’accessoires sans verre ni fond
          isorel.</p>
          <p>Sachez enfin que nos cadres en aluminium vous sont livrés déjà montés, prêts à être
          installés.</p>
          <p>Afin de mettre parfaitement en valeur votre sujet, rentrez ses dimensions dans les cases
          prévues à cet effet dès lors que vous aurez sélectionné le cadre en aluminium qui vous plaît
          et recevez votre commande dans un délai de huit jours ouvrés sauf si vous choisissez notre
          option « EXPRESS » auquel cas nous nous chargerons de votre demande dans un délai d’un
          jour ouvré. Profitez en outre de réductions de 10% dès 250 euros d’achats et de 20% dès
          450 euros d’achats.</p>
          <p>Pour toutes vos questions d’ordre technique, n’hésitez pas à nous contacter en remplissant
          notre formulaire en ligne ou de nous contacter au 03.89.06.00.93 choix 01.</p>
        </div>)
        break;
      case "Cadre avec Marie Louise":
        seoText = (<div className="container mt-6">
          <p>Sublimez vos œuvres picturales et photographiques à l’aide d’un encadrement doté d’une
          marie-louise. Utilisée à l’origine pour encadrer les peintures, la marie-louise est utilisée pour
          éviter que le sujet soit enfermé dans le cadre et a la même fonction que le passe partout. La
          Marie-Louise est utilisée lorsque le sujet n’a pas besoin de verre pour être protégé.</p>
          <p>La boutique en ligne Encadrement sur mesure, spécialisée dans la conception et la
          réalisation de cadres fabriqués dans nos ateliers de Besancon, vous propose une large
          gamme d&#39;encadrements dotés d’une marie-louise. Nous vous les proposons dans plusieurs
          coloris : crème, crème filet or ou crème filet argent.</p>
          <h2>Le cadre doté d’une Marie-Louise : un encadrement esthétique et raffiné</h2>
          <p>Un <strong>cadre avec marie-louise</strong> offre par ailleurs un aspect visuel élégant et permet à l’œil
          observateur de se poser afin de se concentrer sur le travail de l’artiste. Simple et sans
          fioritures, la marie-louise présente un aspect esthétique indéniable qui permet de lire
          l’œuvre tout en la sublimant. Elle est une fenêtre qui nous conduit droit au cœur de
          l’œuvre.</p>
          <p>Un cadre avec une marie-louise créera une superbe transition et laissera respirer
          l’ensemble. Il est conseillé de choisir :</p>
          <ul><li>- une marie louise crème filet or quand le cadre extérieur a des couleurs chaudes ou
          or </li>
          <li>- une marie louise crème filet argent  quand le cadre extérieur a des couleurs froide
          ou argent</li>
          <li>- une marie louise crème quand le cadre extérieur est de couleur contemporaine sans
          rappel d’or ou argent  </li></ul>
          <h2>Comment commander votre cadre doté d’une Marie-Louise ?</h2>
          <p>La marie-louise est proposée dans les tons crème de façon à ne pas dénaturer l’œuvre
          encadrée et à apporter de la luminosité à l’ensemble. Cette ligne raffinée mettra parfaitement en lumière le sujet
          exposé. Le choix, par ailleurs, d’une marie-louise rehaussée d’une bande dorée, accentue
          l’aspect classique.</p>
          <p>La couleur crème permet en outre d’apporter de la profondeur à votre œuvre et d’inviter
          l’observateur à plonger dans le travail, sans que l&#39;attention se noie dans une composition
          trop chargée.</p>
          <p>Afin que le rendu final demeure optimal, rendez-vous sur notre site Internet et renseignez
          les dimensions -en centimètres- de votre oeuvre. Sélectionnez ensuite les couleurs de votre
          <strong>cadre avec marie-louise</strong> puis ajoutez au panier votre commande. La découpe numérique
          permet de couper les cadres très précisément au millimètre.</p>
          <p>Si vous désirez vous rendre compte de l’aspect final, exportez votre sujet à encadrer ou
          votre peinture et simulez un encadrement avec marie-louise.</p>
          <p>Sachez que le délai de traitement de votre commande est de huit jours ouvrés sauf si vous
          choisissez notre option « EXPRESS » auquel cas nous nous chargerons de votre demande
          dans un délai d’un jour ouvré. Profitez en outre de réductions de 10% dès 250 euros
          d’achats et de 20% dès 450 euros d’achats.</p>
          <p>Pour toutes vos questions d’ordre technique, n’hésitez pas enfin à nous contacter en
          remplissant notre formulaire en ligne ou de nous contacter au 03.89.06.00.93 choix 01.</p>
        </div>)
    }
  } else {
    if (pc.couleur === null && pc.categorie === null) {
      if (pc.dimension[0] === 30 && pc.dimension[1] === 40) {
        seoText = (<div className="container mt-6">
          <p>Fier de votre photographie, vous désirez la mettre en valeur et l’exposer dans un cadre qui
          la sublimera. Votre œil d’expert ne peut concevoir en effet que votre photo se retrouve
          coincée dans un cadre au format trop juste ou au contraire, flottant au milieu d’un cadre aux
          dimensions non-adaptées. Une œuvre s’apprécie et se comprend bien souvent
          accompagnée de son cadre. Sans lui, elle est comme dépossédée d’une partie de son
          essence. L’importance du cadre demeure donc considérable.</p>
          <p>Tournez-vous vers les services et l’expertise d&#39;Encadrement sur mesure. Notre boutique en
          ligne propose en effet de superbes encadrements dans des coloris, des formats variés en
          bois ou aluminium. Découvrez notamment notre large gamme de cadres au format 30 x 40
          cm qui magnifieront vos tirages photographiques.</p>
          <p>Sélectionnez le <strong>cadre 30x40</strong> cm qui vous intéresse puis renseignez les éléments demandés
          -format, fond isorel ou non- et choisir parmi les 5 types de verre adapté à votre besoin et
          n’hésitez pas à importer votre image afin de vous faire une idée du rendu final.</p>
          <h2>Le cadre 30x40 : le format idéal pour encadrer vos photographies</h2>
          <p>Tous les professionnels de l’encadrement vous le diront, le <strong>cadre de 30x40</strong> cm est le format
          idéal pour encadrer vos impressions 20x30 cm. Exposez vos photographies, mais aussi par
          exemple vos diplômes afin de les valoriser.</p>
          <p>Mettre en valeur une photographie n’est pas toujours chose aisée. Mieux vaut ne pas se
          tromper dans le format de votre cadre. C’est pourquoi parmi les formats standards nous
          vous conseillons de choisir un cadre 30x40 cm dont le rendu reste esthétique.</p>
          <p>Afin de mettre davantage votre photographie en valeur, choisissez un cadre de la même
          couleur que le second coloris dominant sur votre cliché. Ce rappel de couleur permettra de
          ne pas donner plus d&#39;importance au cadre qu&#39;à l’œuvre encadrée. Pour un style minimaliste,
          optez pour un cadre 30x40 cm simple, en aluminium au contraire, si vous souhaitez apporter
          de la chaleur, un cadre en bois sera parfait.</p>
          <p>Encadrer une œuvre ne s’arrête pas au choix du cadre. Pensez à intégrer dans votre cadre
          30x40 cm un passe-partout si vous voulez encadrer une photo par exemple de dimension
          20x30cm.</p>
          <h2>Le passe-partout : la touche esthétique de vos encadrements 30x40</h2>
          <p>Un passe-partout est une pièce en carton placée entre le cadre et l’œuvre. Généralement de
          couleur claire, il crée un espace entre la photographie et le verre du cadre.</p>
          <p>Il offre par ailleurs une aération visuelle à l’ensemble -cadre et œuvre- qui sublime aussi
          bien la photographie que le cadre. Grâce au passe-partout, vous conférez de la profondeur
          à votre travail.</p>
          <p>Si le sujet que vous voulez glisser dans le cadre mesure réellement 20x30cm , il suffit de
          commander un passe partout de dimension fenêtre image ( le trou du passe partout qui  se
          positionne sur votre photo ), 19.5x29.5cm afin d’éviter que la photo passe à travers …et de
          dimension extérieure 30x40cm si le cadre commandé mesure cette dimension .</p>
          <p>Il est à noter que si vous commandez le cadre et que vous répondez à la question :voulez-
          vous un passe partout ? vous constaterez que le changement de la dimension de la fenêtre
          image se fera automatiquement donc dans ce cas il faudra indiquer la dimension réelle de la
          photo.</p>
        </div>)
      } else if (pc.dimension[0] === 50 && pc.dimension[1] === 70) {
        seoText = (<div className="container mt-6">
          <p>Exposer une photographie personnelle ou professionnelle dans un cadre est une manière
idéale de la mettre en valeur, pour en magnifier la dimension esthétique. La photographie
est en effet un art à part entière, et comme les plus beaux tableaux dans les musées, votre
sujet mérite un encadrement adapté propre à le sublimer.</p>
<p>Si vous avez l’intention d’encadrer votre photographie pour en renforcer le charme et la
beauté spécifiques, Encadrement sur mesure dispose de l’expertise requise pour vous offrir
de superbes encadrements proportionnés, disponibles en une grande variété de styles, de
coloris ou de formats. Nous vous invitons notamment à découvrir notre large gamme de
cadres au format 50x70 cm, qui vous permettront d’embellir harmonieusement vos tirages
photographiques. Un <strong>cadre 50x70</strong> cm est en effet particulièrement idéal pour mettre en
valeur votre photographie et en retrouver toute la dimension artistique.</p>
<h2>Le format 50x70 ou B2, un cadre adapté pour valoriser son sujet</h2>
<p>Un <strong>cadre 50x70</strong> cm est un cadre au format B2, réputé pour être le plus petit des « grands
formats ». Favorisant la largeur sur la hauteur, cette dimension d’encadrement est
<strong>notamment utilisée pour les posters</strong> ou <strong>affiches publicitaires</strong>, car elle incite le regard au
mouvement et confère ainsi un certain dynamisme à l’ensemble. On retrouve par ailleurs ce
format pour encadrer de nombreux tableaux, dans la mesure où il permet de respecter leurs
proportions, ainsi que leur harmonie originelle. Lorsqu’ils sont ornés de dorures, ces cadres
peuvent par ailleurs devenir eux-mêmes de véritables objets esthétiques.</p>
<p>Pour favoriser la mise en valeur de votre photographie, il peut être intéressant de
sélectionner un cadre 50x70 cm dont le coloris diffère de la couleur dominante de votre
sujet, afin de créer une harmonie et de ne pas accorder une importance trop grande à votre
cadre au détriment de votre photographie.</p>
<p>Pour vous donner un aperçu de l’aspect de votre <strong>cadre 50x70</strong> cm, vous pouvez en modifier
les caractéristiques sur notre site, en choisissant notamment le coloris souhaité, la quantité
voulue, ou encore en décidant de l’ajout ou non d’un fond isorel ou d’un passe-partout.
Selon la photographie à encadrer, vous pourrez par ailleurs opter pour un cadre en
aluminium ou en bois, afin de conférer à votre sujet un charme particulier, emprunt de
classicisme ou de modernité.</p>
<h2>Quelle largeur de passe-partout choisir pour un cadre 50x70 ?</h2>
<p>Un passe-partout est une pièce en carton que l’on place entre l’encadrement et la
photographie. Généralement de couleur claire, le passe-partout est aussi pratique
qu’esthétique. En effet, il a pour fonction de créer de l’espace entre le verre et le sujet afin
de le protéger d’altération dues au contact. Le passe-partout confèrera de la profondeur à
votre photographie.</p>
<p>Pour un <strong>cadre 50x70</strong>, il est recommandé de choisir un passe-partout ayant la même
dimension et donc la même largeur. Un passe-partout disproportionné n’aura aucun intérêt
pour votre encadrement. S’il est trop grand il ne sera pas esthétique, et s’il est trop petit il
perdra tout intérêt visuel.</p>
        </div>)
      } else if (pc.dimension[0] === 50 && pc.dimension[1] === 50) {
        seoText = (<div className="container mt-6">
<p>Pour mettre en valeur une photographie dont on est fier ou dont on apprécie la dimension
esthétique, il est judicieux de l’exposer dans un cadre et de l’accrocher sur un mur. Tout
comme les plus belles toiles du Louvres qui s’apprécient également pour leur cadre doré si
caractéristique permettant de les sublimer, vos photographies nécessitent un cadre au
format adapté et proportionné, afin de lui conserver son charme et sa spécificité.</p>
<p>Si vous souhaitez, vous aussi, embellir vos photographies en les encadrant, la boutique
Encadrement sur mesure peut vous offrir son expertise en vous proposant de magnifiques
encadrements ayant des aspects, des coloris ou des formats différents. Nous vous invitons
notamment à découvrir notre large gamme de <strong>cadres au format 50x50 cm</strong>, qui vous
permettront de magnifier vos tirages photographiques. Un cadre 50x50 cm est en effet
particulièrement idéal pour mettre en valeur vos photographies et en préserver toute
l’harmonie.</p>
<h2>Le format carré 50x50, idéal pour valoriser le centre du sujet</h2>
<p>Un <strong>cadre 50x50</strong> cm est un cadre au format carré disposant de toutes les caractéristiques
pour valoriser esthétiquement vos photographies. En effet, le cadre 50 x 50 cm possède les
proportions parfaites pour mettre en valeur son centre. Il convient donc totalement pour
sublimer harmonieusement des photographies de visages ou de paysages. Les
photographes apprécient ainsi tout particulièrement ce format, permettant d’encadrer leur
sujet en respectant les proportions recherchées.</p>
<p><strong>Pour mettre davantage en valeur votre photographie</strong>, il peut être intéressant d’opter pour
un cadre 50x50 cm dont le coloris diffère de la couleur dominante de votre sujet, afin de
créer une harmonie et de ne pas accorder plus d’importance à l’encadrement qu’à la
photographie. La taille des profils de votre cadre 50x50 cm est également un critère à
prendre en compte pour ne pas étouffer votre sujet.</p>
<p>Par ailleurs, selon la photographie à encadrer, vous pourrez opter pour un cadre en
aluminium ou en bois, afin de conférer à votre sujet et plus largement à votre décoration
murale une atmosphère spécifique, moderne ou classique.</p>
<h2>Commander votre cadre 50x50 en quelques clics</h2>
<p>Nous vous donnons la possibilité de détailler le processus de votre commande afin qu’elle
corresponde parfaitement à tous vos critères, notamment concernant le type de cadre 50x50
cm choisi parmi la grande variété d’encadrements que nous vous proposons. Quelques clics
suffiront pour obtenir un aperçu de votre cadre idéal.</p>
<p>Sélectionnez donc le <strong>cadre 50x50</strong> cm qui vous intéresse puis renseignez les éléments
demandés selon vos préférences. Vous pourrez notamment choisir le coloris souhaité, la
quantité désirée, ou encore décider d’ajouter ou non un fond isorel ou un passe-partout.</p>
<p>Un passe-partout est une pièce en carton que l’on place entre l’encadrement et la
photographie. Généralement de couleur claire ou noire mais qui existe dans de nombreuses
variantes et spécificités, le passe-partout est aussi pratique qu’esthétique. Son rôle est de
créer un espace entre le verre de protection du cadre et le sujet, ce qui évite un contact
pouvant altérer votre sujet dans le temps. En offrant une aération visuelle à l’ensemble, le
passe-partout confèrera de la profondeur à votre photographie.</p>
        </div>)
      } else if (pc.dimension[0] === 60 && pc.dimension[1] === 80) {
        seoText = (<div className="container mt-6">
          <p>Le cadre est un élément essentiel de l’œuvre. Il la met en valeur tout en la protégeant. Mal
          choisi, il gâcherait l’ensemble, le rendu escompté ne serait pas au rendez-vous. Son format
          doit tout particulièrement être pensé, car c’est lui qui sublimera le travail de l’artiste. Il ne faut
          surtout pas croire que le cadre n’est qu’un simple accessoire, il est bien plus, il fait partie
          intégrante de l’œuvre et sans lui, cette dernière perdrait du sens.</p>
          <p>Découvrez sur la boutique en ligne d’Encadrement sur mesure de multiples formats et
          notamment des cadres 60x80 cm — proche du format A1 — qui permettront d’exposer de la
          plus belle des façons vos œuvres picturales ou photographiques.</p>
          <h2>Le cadre 60x80 : histoire d’un format idéal pour les portraits</h2>
          <p>Le <strong>cadre de 60x80 cm</strong> est parfait notamment pour les portraits, car il est suffisamment
          grand pour mettre en valeur le sujet et cela, sans en déformer les traits.</p>
          <p>L’histoire du cadre est riche d’informations sur la façon d’envisager une œuvre d’art et cela,
          quelle que soit la technique choisie. Cette histoire a connu des périodes de creux et de
          rebondissements.</p>
          <p>Le cadre, dans un premier temps, est pour ainsi dire oublié de l’époque médiévale où le
          support — généralement en bois — se suffisait à lui-même. Il faut attendre le début du 15e
          siècle pour que le cadre ou plutôt la bordure — le terme « cadre » n’apparaîtrait qu’au milieu
          du 16e siècle — trouve sa place, notamment avec l’apparition des châssis entoilés. Dès lors,
          le cadre accompagne l’œuvre et guide le regard de l’observateur. S’en passer, comme l’ont
          fait au 20e siècle des artistes contemporains tels que Malevitch, prend alors également du
          sens.</p>
          <p>Si les portraits médiévaux ont tendance à présenter de petits formats à l’image de L’Homme
          au turban rouge - National Gallery, 1433, huile sur panneau, 26 x 19 cm - de Jan Van Eyck,
          qui figure parmi les premiers tableaux à nous être parvenu entourés de son cadre d’origine,
          force est de constater que les dimensions augmentent au fur et à mesure qu’il est possible
          de monter des châssis plus grands, selon les thèmes abordés et selon le commanditaire,
          comme les portraits royaux en pied des Bourbons.</p>
          <p><strong>Choisissez sans plus hésiter un cadre 60 x 80 et sublimez ainsi vos portraits !</strong></p>
          <h2>Passe-partout pour cadre 60x80</h2>
          <p>Encadrement sur mesure vous propose de nombreux coloris de baguettes pour vos <strong>cadres
          au format 60x80 cm</strong>. Choisissez parmi les couleurs suivantes : noir, blanc, or, cuivre,
          argent, bordeaux, rouge, bleu, violet, bois, marron, crème, jaune, gris, orange, vert et rose.
          Sachez que vous prendrez toujours moins de risques en optant pour des teintes sobres qui
          n’écraseront pas les nuances de votre cliché ou de votre peinture contrairement aux
          couleurs vives. Ces dernières permettent d’ailleurs de souligner par effet de rappel une
          couleur dominante.</p>
          <p>Magnifiez davantage le sujet grâce à un passe-partout. Le format 60 x 80 ira parfaitement
          dans un cadre de même taille. Considérez que la fenêtre est, elle, plus réduite. Elle est de
          l’ordre de 50 x 70 cm.</p>
        </div>)
      } else if (pc.dimension[0] === 21 && pc.dimension[1] === 29.7) {
        seoText = (<div className="container mt-6">
<p>Encadrez vos clichés au format A4 grâce à un cadre adapté. Le format A4 est certainement
le plus répandu, vous ne manquerez pas de dénicher le modèle que vous aviez imaginé
dans l’optique de mettre en lumière vos plus beaux moments de vie. Accrochez ainsi aux
murs vos photographies de vacances, de famille et partagez vos meilleurs souvenirs.
Nous vous invitons à sélectionner sur le site de la boutique en ligne d’Encadrement sur
mesure le <strong>cadre A4</strong> de votre choix qui magnifiera votre sujet. Nos équipes vous proposent
pour ce faire un large choix de couleurs de baguettes.</p>
<h2>Le cadre A4 parfait avec ou sans passe-partout</h2>
<p>Le <strong>cadre A4 -21x29.7 cm</strong>- se trouve parmi les plus courants, car il met en valeur tous les
sujets aussi bien au format paysage qu&#39;au format portrait. Utilisez ou non un passe-partout.
Si vous penchez pour la première option, prenez un cadre légèrement plus grand au format
30x40 cm, idéal pour l&#39;encadrement A4. Nous vous proposons pour cela de nombreux
passe-partout dotés d’une âme de coloris blanc, blanc cassé, teinté, pigmenté ou noir de
différentes épaisseurs : 1.4 mm, 1.5 mm, 1.7 mm, 2.5 mm, 3.3 mm.</p>
<p>Rendez-vous à la rubrique <Link to="/passe-partout/">« Passe-partout »</Link> puis sélectionnez votre passe-partout. Il s’agit
là d’une étape importante qui permettra de guider le regard et qui mettra en valeur votre
composition. Privilégiez le blanc cassé ou le blanc qui restent neutres et ne prennent par
conséquent pas l’ascendant sur la photographie. Commandez un échantillon de passe-
partout, au format 10x10 cm -fenêtre image : 5x5 cm- afin de vous faire une idée du rendu
final.</p>
<p>Si, au contraire, vous choisissez la seconde option, concentrez-vous alors sur le choix de la
couleur de votre cadre A4. Celle-ci doit mettre en valeur votre photographie afin de la
projeter et non de l’écraser.</p>
<h2>Exposez tous les sujets grâce à un cadre A4</h2>
<p>Le choix d’un cadre A4 a cela d’intéressant qu’il met indistinctement en valeur tous les types
de sujets. Il reste notamment idéal pour l’encadrement de portraits. Agrandir une
photographie au format A4 afin de l’exposer chez vous ou pour l’offrir est idéal car la netteté
de votre image est conservée tout en étant suffisamment grande pour faire l’objet d’un
affichage mural.</p>
<p>Le <strong>cadre A4 demeure par ailleurs un format parfait pour exposer des diplômes ou
autres documents administratifs d’importance.</strong></p>
<p>Indiquez sur notre site Internet les dimensions de votre photographie puis choisissez le
cadre A4 de votre choix, en aluminium ou en bois. Nous présentons une large palette de
couleurs d’encadrement, de quoi vous permettre de magnifier comme il se doit votre
photographie : noir, blanc, or, cuivre, argent, bordeaux, rouge, bleu, violet, bois, marron,
crème, jaune, gris, orange, vert ou encore rose.</p>
<p>Une fois votre cadre A4 sélectionné, importez votre photographie afin de voir le rendu puis
sélectionnez votre service : précoupé ou coupé assemblé avec option passe-partout, carton
barrière et protection. Passez enfin votre commande et recevez-la dans un délai de huit
jours ouvrés.</p>
        </div>)
      }
    }
  }

  const { site, allFile } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allFile(filter: { relativePath: { regex: "/^categories/" } }) {
          nodes {
            name
            relativePath
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
      }
    `
  )
  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title={title}
          description={`${title} - Votre cadre sur mesure assemblé à Besançon en France au prix d'un cadre standard - Grand choix de cadres bois et aluminium`}
          canonical={`${site.siteMetadata.siteUrl}/${props.pageContext.canonical}`}
          jsonLd={[
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Accueil",
                  item: site.siteMetadata.siteUrl,
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Cadre sur mesure",
                  item: `${site.siteMetadata.siteUrl}/cadres/`,
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: title,
                  item: `${site.siteMetadata.siteUrl}/${props.pageContext.url}`,
                },
              ],
            },
          ]}
        />
        <nav className="breadcrumb mb-0 mt-4">
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <Link to="/cadres/">Cadre sur mesure</Link>
            </li>
            <li>
              <span className="pl-3">{title}</span>
            </li>
          </ul>
        </nav>

        <div className="columns mt-0">
          <div className="column">
            <div
              className="mt-4 p-4"
              style={{ marginBottom: "2rem", background: "#f3f3f3", border: pageProduit.dimensionsDefault ? "4px solid #c7001e" : "none" }}
            >
              {Object.keys(photosR.photos).length >= 1 ? renderTiragePhoto() : <ChoixDimensions pageContext={pc} />}
            </div>

            {pc.categorie?.cat_id === 5 ? (<div
              className="mb-5 p-4 pb-5"
              style={{ border: currentCaisseMontage === null && !pageProduit.dimensionsDefault ? "4px solid #c7001e" : "none", background: "#f3f3f3" }}
            >
              <div className="familyHeader">
                <div className="familyHeaderTitle">Je sélectionne le type de montage de ma caisse américaine&nbsp;:</div>
                <div className="familyHeaderContent mt-2 columns" style={{ justifyContent: "center" }}>
                  {currentCaisseMontage === null && !pageProduit.dimensionsDefault ?
                    <Pulse forever={true} duration={1000}>
                      <span className="has-text-primary ml-3 mr-3">
                        <FontAwesomeIcon icon={faHandPointRight} size="3x" />
                      </span>
                    </Pulse> : null}
                  {(pageProduit.pp.type === "aucun" || pageProduit.pp.type === null) && !photosR.tirage ? <button onClick={(evt) => {
                    setCurrentCaisseMontage([10, 18]);
                    if (currentColor) setCurrentColor(null);
                    window.scroll({top: evt.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                  }} className="ml-2 mr-2 p-2 button" style={{ border: currentCaisseMontage?.indexOf(10) >= 0  ? "2px solid #c7001e" : "1px solid #dbdbdb", "display": "block", "height": "auto" }}>
                    <GatsbyImage image={getImage(getImage(allFile.nodes.find((f) => f.name === 'caisse1')))} alt="Chassis peinture" style={{width: 200}} />
                    <div className="mt-2">Châssis peinture</div>
                  </button> : null}
                  {(pageProduit.pp.type === "aucun" || pageProduit.pp.type === null) && (!photosR.tirage || photosR.tirage === "tirage_sublimation") ? <button onClick={(evt) => {
                    setCurrentCaisseMontage([3]);
                    if (currentColor) setCurrentColor(null);
                    window.scroll({top: evt.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                  }} className="ml-2 mr-2 p-2 button" style={{ border: currentCaisseMontage?.indexOf(3) >= 0  ? "2px solid #c7001e" : "1px solid #dbdbdb", "display": "block", "height": "auto" }}>
                    <GatsbyImage image={getImage(getImage(allFile.nodes.find((f) => f.name === 'caisse2')))} alt="Support rigide" style={{width: 200}} />
                    <div className="mt-2">Support rigide</div>
                  </button> : null}
                  {photosR.tirage !== "tirage_sublimation" ? <button onClick={(evt) => {
                    setCurrentCaisseMontage([12]);
                    if (currentColor) setCurrentColor(null);
                    window.scroll({top: evt.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                  }} className="ml-2 mr-2 p-2 button" style={{ border: currentCaisseMontage?.indexOf(12) >= 0  ? "2px solid #c7001e" : "1px solid #dbdbdb", "display": "block", "height": "auto" }}>
                    <GatsbyImage image={getImage(getImage(allFile.nodes.find((f) => f.name === 'caisse3')))} alt="Support papier" style={{width: 200}} />
                    <div className="mt-2">Support papier</div>
                  </button> : null}
                </div>
              </div>
            </div>) : null}

            <div
              className="mb-5 p-4 pb-5"
              style={{ border: currentColor === null && (pc.categorie?.cat_id !== 5 || currentCaisseMontage !== null) && !pageProduit.dimensionsDefault ? "4px solid #c7001e" : "none", background: "#f3f3f3" }}
            >
              <ChoixCouleur pc={pc} setCurrentColor={setCurrentColor} currentColor={currentColor} currentCaisseMontage={currentCaisseMontage} />
            </div>
            <h1>{`${pc.categorie !== null ? `${pc.categorie.cat_libelle}` : "Cadre"} ${
                  pc.couleur !== null ? pc.couleur.libelle.toLocaleLowerCase() : ""}${
                  pc.dimension !== null ? (pc.dimension[2] ? pc.dimension[2] : `${pc.dimension[0]}x${pc.dimension[1]} cm`)  : ""}`}</h1>
            {props.pageContext.dimension !== null || pageProduit.hauteur !== null ? (
              <p className="has-text-right">
                <small>
                  * : Prix TTC pour un cadre coupé assemblé sans protection sans isorel.{" "}
                  {pageProduit.largeur !== "" ? (
                    <>
                      Dimensions {![null, "aucun"].includes(pageProduit.pp.type) ? "de chaque sujet" : "du sujet"} : {pageProduit.largeur}cm x{" "}
                      {pageProduit.hauteur}
                      cm.
                    </>
                  ) : (
                    ""
                  )}
                  {Object.keys(photosR.photos).length > 1
                    ? ` Passe-partout multivues de ${Object.keys(photosR.photos).length} fenêtre${Object.keys(photosR.photos).length > 1 ? "s" : ""}`
                    : [null, "aucun"].includes(pageProduit.pp.type)
                    ? ""
                    : ` Passe-partout multivues de ${pageProduit.pp.ouvertures[0] * pageProduit.pp.ouvertures[1]} fenêtre${
                        pageProduit.pp.ouvertures[0] * pageProduit.pp.ouvertures[1] > 1 ? "s" : ""
                      }`}
                  {![null, "aucun"].includes(pageProduit.pp.type) ? "  extra blanc 1,4mm" : null}
                </small>
              </p>
            ) : null}

            <div ref={scrollToProducts}></div>
            {renderProducts(pc)}
          </div>
          <aside className="column is-narrow">
            <div style={{ width: 350 }}>
              <Categories categories={Object.values(pc.filtersData.categories).filter((e) => filterSizes(e))} pc={pc} />
              <CategDescription catId={pc?.categorie?.cat_id} />
            </div>
          </aside>
        </div>
        {Object.values(photosR.photos).length === 0 ? (
          <div className="columns">
            <div className="column"></div>
            <div className="column has-text-right pr-6 is-narrow">
              <div className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                  <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" data-cy="estimer-prix-std-famille">
                    <span>Cadre par couleur</span>
                    <span className="icon"></span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content has-text-left">
                    {Object.values(pc.filtersData.couleurs).map((e, key) => (
                      <Link to={`/cadres/${e.slug}/`} key={key} className="dropdown-item">
                        {e.libelle}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="column has-text-right pr-6 is-narrow">
              <div className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                  <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" data-cy="estimer-prix-std-famille">
                    <span>Cadre par dimensions populaires</span>
                    <span className="icon"></span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    {Object.values(pc.filtersData.dimensions).map((e, key) => (
                      <Link to={`/cadres/${e[0]}x${e[1]}/`} key={key} className="dropdown-item">
                        {`Cadre ${e[2] ? `${e[2]} - ` : ""}${e[0]}x${e[1]}`}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="column"></div>
          </div>
        ) : null}
        {seoText}
      </Layout>
    </>
  )
}

FamilysPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default FamilysPage
