import React, { useEffect, useState } from "react"
import ReactTooltip from "react-tooltip"
import PropTypes from "prop-types"
import Pulse from "react-reveal/Pulse"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getSizeInfos } from "../../profcadresprix/index-legacy"
import { useSelector } from "react-redux"
import { filterTirage, getFilterMaxis } from "../../services/cadre"
import { graphql, navigate, useStaticQuery } from "gatsby"

const ChoixCouleur = (props) => {
  const pageProduit = useSelector((s) => s.PageProduit)
  const photosR = useSelector((s) => s.photosR)
  const filterSizes = (e) =>
    (getSizeInfos(pageProduit).perimetreIntra <= getFilterMaxis(e, photosR.tirage).ba_perimetre_maxi &&
      getSizeInfos(pageProduit).largeurIntra <= getFilterMaxis(e, photosR.tirage).ba_maxi &&
      getSizeInfos(pageProduit).hauteurIntra <= getFilterMaxis(e, photosR.tirage).ba_maxi) ||
    ([null, "aucun"].includes(pageProduit.pp.type) &&
      getSizeInfos(pageProduit).perimetreIntra <= 600 &&
      getSizeInfos(pageProduit).largeurIntra <= 150 &&
      getSizeInfos(pageProduit).hauteurIntra <= 150)

  const vignettes = useStaticQuery(graphql`
    query CouleursQuery {
      allFile(filter: { relativePath: { regex: "/^couleurs//" } }) {
        nodes {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(width: 200)
          }
        }
      }
    }
  `)

  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    setIsMounted(true);
  },[]);

  return (
    <div className="familyHeader">
      <div className="familyHeaderTitle">Je sélectionne la couleur de mon cadre&nbsp;:</div>
      <div className="familyHeaderContent mt-2 columns" style={{ justifyContent: "center" }}>
        {isMounted ? <ReactTooltip /> : null}
        {props.currentColor === null && (props.pc.categorie?.cat_id !== 5 || props.currentCaisseMontage !== null) && !pageProduit.dimensionsDefault && (
          <Pulse forever={true} duration={1000}>
            <span className="has-text-primary ml-3">
              <FontAwesomeIcon icon={faHandPointRight} size="3x" />
            </span>
          </Pulse>
        )}
        <div className="ml-3 couleurs">
          {Object.values(props.pc.filtersData.couleurs)
            .filter((e) => e.libelle !== "Toutes" && filterSizes(e) && (filterTirage(e, photosR.tirage) || photosR.tirage == null) 
              && (props.pc.categorie?.cat_id !== 5 || props.currentCaisseMontage === null
                || e.cat_ids_originales.some(idOrig => props.currentCaisseMontage.indexOf(idOrig) >= 0)))
            .sort((a, b) => a.cog_order - b.cog_order)
            .map((e, key) => (
              <div
                onClick={(evt) => {
                  props.setCurrentColor(e.id);
                  window.scroll({top: evt.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});

                  if (props.pc.couleur) {
                    navigate(`/cadres/${e.slug}/`)
                  }
                }}
                key={key}
                data-tip={e.libelle}
                data-effect="solid"
                className={`couleur is-relative ${props.currentColor === e.id ? "active" : ""}`}
                tabIndex={0}
                role="button"
                onKeyPress={() => {}}
              >
                <div style={{ background: e.hexa }}>
                  {vignettes.allFile.nodes.find((f) => parseInt(f.name) === e.id) ? (
                    <GatsbyImage
                      image={getImage(vignettes.allFile.nodes.find((f) => parseInt(f.name) === e.id))}
                      className="imgFiltre"
                      style={{ height: "100%", marginTop: -11 }}
                      alt={e.libelle}
                    />
                  ) : null}
                  <div className="couleurTxt">{e.libelle}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

ChoixCouleur.propTypes = {
  pc: PropTypes.object,
  currentCaisseMontage: PropTypes.array,
  currentColor: PropTypes.number,
  setCurrentColor: PropTypes.func,
}

export default ChoixCouleur
